import { gql } from "@apollo/client";

export const BUNDLE_PRODUCT_FRAGMENT = gql`
fragment BundleProductFragment on BundleProduct{
  items{
    sku
    title
    type
    uid
    option_id
    options{
      id
      quantity
      uid
      label
    }
  }
}
`

export const DOWNLOADABLE__PRODUCT_FRAGMENT = gql`
fragment DownloadableFragment on DownloadableProduct {
  links_title
  links_purchased_separately
  downloadable_product_links {
    sample_url
    sort_order
    title
  }
}
`;

export const PRICE_FRAGMENT = gql`
fragment PriceFragment on ProductInterface {
  meta_title
  meta_keyword
  meta_description
  price_tiers {
    discount {
      amount_off
      percent_off
    }
    final_price {
      currency
      value
    }
    quantity
  }
  # show_price_without_Tax
      display_tax {
        regular_price_without_tax
        regular_price_with_tax
        final_price_without_tax
        final_price_with_tax
        base_price
        price
        tax
        rate
  }
  price_range {
    maximum_price {
      discount {
        amount_off
        percent_off
        
      }
      final_price {
        currency
        value
        
      }
      fixed_product_taxes {
        amount {
          currency
          value
          
        }
        label
        
      }
      regular_price {
        currency
        value
        
      } 
    } 
  }
}
`;

export const CONFIGUREABLE_PRODUCT_FRAGMENT = gql`
${PRICE_FRAGMENT}
fragment ConfigurableProductFragment on ConfigurableProduct {
   canonical_url
   configurable_options {
        attribute_code
        label
        values {
            uid
            label
            swatch_data {
                value
            }
        }
    }
    variants {
        attributes {
            code
            uid
            label
        }
        product {
            id
            url_key
            canonical_url
            meta_title
            meta_description
            meta_keyword
            only_x_left_in_stock
            is_available
            availability{
        status
        label
      }
      custom_stock_item{
          qty
          min_sale_qty
        }
            media_gallery{
              url
              label
              disabled
              position
              __typename
            }
            small_image{
                url
            }
            sku                      
            name
            short_description{
              html
            }
            description{
              html
            }
            stock_status
            custom_attributes{
              attribute_metadata{
                code
              }
              entered_attribute_value{
                value
              }
              selected_attribute_options{
                attribute_option{
                  label
                  swatch_image
                  uid
                }
              }
            }
            visible_custom_attributes{
              attribute_code
              attribute_label
              attribute_value
            }
            ...PriceFragment
        }
    }
    configurable_product_options_selection {
        options_available_for_selection {
            attribute_code
            option_value_uids
        }
    }
  }
`;


export const RELATED_PRODUCTS_FRAGMENT = gql`
 fragment RelatedProductsFragment on ProductInterface{
    meta_title
    meta_keyword
    meta_description
    related_products{
      id
      uid
      name
      sku
      is_available
      availability{
        status
        label
      }
      custom_stock_item{
          qty
          min_sale_qty
        }
      canonical_url
      is_new 
      best_seller
      on_sale
      new_from_date
      new_to_date
      categories{
        url_path
        level
      }
      small_image {
        url
        __typename
      }
      image {
        url
        __typename
      }
      stock_status
      rating_summary
      __typename
      url_key
      brand
      custom_attributes{
        attribute_metadata{
          code
        }
        entered_attribute_value{
          value
        }
        selected_attribute_options{
          attribute_option{
            label
            swatch_image
            uid
          }
        }
      }
      only_x_left_in_stock
      # show_price_without_Tax
      display_tax {
        regular_price_without_tax
        regular_price_with_tax
        final_price_without_tax
        final_price_with_tax
        base_price
        price
        tax
        rate
  }
      price_range {
        maximum_price {
          discount {
            amount_off
            percent_off
            
          }
          final_price {
            currency
            value
            
          }
          fixed_product_taxes {
            amount {
              currency
              value
              
            }
            label
            
          }
          regular_price {
            currency
            value
          } 
        } 
      }
    }
 }
`;

export const PRODUCT_FRAGMENT = gql`
${PRICE_FRAGMENT}
 fragment ProductFragment on ProductInterface{
    meta_title
    meta_keyword
    meta_description
    id
    uid
    name
    sku
    model_no
    is_available
    clearance
    has_low_price
    negotiated_discount
    normal_discount
    availability{
        status
        label
      }
      custom_stock_item{
          qty
          min_sale_qty
        }
    canonical_url
    is_new
    best_seller
    on_sale
    new_from_date
    new_to_date
    files {
      url
      title
      id
      size
    }
    media_gallery{
      url
      label
      disabled
      position
      __typename
    }
    categories {
      id
      name
      url_path
      breadcrumbs {
        category_name
        category_url_path
        category_level
      }
    }
    small_image {
      url
      __typename
    }
    image {
      url
      __typename
    }
    short_description{
      html
    }
    description{
      html
    }
    stock_status
    rating_summary
    __typename
    url_key
    brand
    review_count
    country_of_manufacture
    bugnard_choice
    reviews {
      items {
        created_at
        average_rating
        text
        nickname
        summary
        ratings_breakdown {
          name
          value
        }
      }
    }
    custom_attributes{
      attribute_metadata{
        code
      }
      entered_attribute_value{
        value
      }
      selected_attribute_options{
        attribute_option{
          label
          swatch_image
          uid
        }
      }
    }
    visible_custom_attributes{
      attribute_code
      attribute_label
      attribute_value
    }
    only_x_left_in_stock
    ...PriceFragment
    ... on BundleProduct{
      items{
        sku
        title
        type
        uid
        option_id
        options{
          id
          quantity
          uid
          label
        }
      }
    }
    variant_products{
      sku
    }
 }
`;


export const CUSTOM_ATTRIBUTE_FRAGMENT = gql`
fragment CustomAttributeFragment on VisibleCustomAttributes {
  attribute_code
  attribute_label
  attribute_values
  has_border
  searchable
}
`

export const RELATION_PRODUCT_FRAGMENT = gql`
fragment RelationProductFragment on ProductInterface {
  name
  sku
  url_key
  small_image {
    url
    __typename
  }
  display_tax {
    regular_price_without_tax
    regular_price_with_tax
    final_price_without_tax
    final_price_with_tax
  }
  custom_stock_item{
    min_sale_qty
  }
  price_range {
    maximum_price {
      discount {
        amount_off
      }
    }
  }
  availability{
    status
    label
  }
}
`;