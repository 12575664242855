import { gql } from "@apollo/client";

export const ADDRESS_FRAGMENT = gql`
fragment AddressFragment on CustomerAddress{
  id
  firstname
  lastname
  street
  city
  company
  postcode
  country_code
  telephone
  vat_id
  default_billing
  default_shipping
  region_id
  itm_address_id
  region {
    region_id
    region_code
    region
    __typename
  }
  suffix
  prefix
}
`

export const CUSTOMER_FRAGMENT = gql`
${ADDRESS_FRAGMENT}
fragment CustomerFragment on Customer {
  firstname
  lastname
  is_company
  email
  is_subscribed
  date_of_birth
  store_id
  website_id
  store_code
  role
  is_disabled
  itm_cardcode
  files{
    title
    url
  }
  companies{
    parent_company
    company_id
    company_code
    is_default
    role
    company_name
  }
  addresses {
    ...AddressFragment
  }
  orders {
    total_count
  }
}
`

export const CUSTOMER_ORDER_FRAGMENT = gql`
fragment CustomerOrder on CustomerOrders {
  page_info {
    total_pages
    current_page
    page_size
  }
  items {
    id
    order_date
    number
    status
    billing_address {
      firstname
      lastname
      city
      company
      country_code
      postcode
      telephone
      street
      region
    }
    shipping_address {
      firstname
      lastname
      city
      company
      country_code
      postcode
      telephone
      street
      region
    }
    payment_methods {
      name
    }
    files {
      title
      url
      __typename
    }
    total {
      grand_total {
        value
        __typename
      }
      __typename
    }
    invoices {
      id
      files {
        title
        url
        __typename
      }
      total {
        grand_total {
          value
        }
        subtotal {
          value
        }
        taxes {
          amount {
            value
          }
        }
        total_tax {
          value
        }
        total_shipping {
          value
        }
      }
      items {
        product_name
        product_sku
        product_sale_price {
          value
        }
        quantity_invoiced
      }
    }
    shipments {
      id
      files {
        title
        url
        __typename
      }
      tracking {
        number
      }
      items {
        product_name
        product_sku
        quantity_shipped
      }
    }
    total {
      grand_total {
        value
      }
      subtotal {
        value
      }
      taxes {
        amount {
          value
        }
      }
      total_tax {
        value
      }
      total_shipping {
        value
      }
      discounts {
        amount {
          value
        }
      }
    }
    items {
      id
      product_url_key
      product_name
      product_sku
      product_sale_price {
        value
      }
      product_small_image{
        url
        label
      }
      selected_options{
        label
        value
      }
      quantity_ordered
    }
    __typename
  }
  __typename
}
`

export const CUSTOMER_ORDERS_FRAGMENT = gql`
${CUSTOMER_ORDER_FRAGMENT}
fragment CustomerOrdersFragment on Customer {
  orders(currentPage: $currentPage, pageSize: $pageSize) {
      ...CustomerOrder
  }
}
`;

