import { ApolloProvider } from "@apollo/client";
import { useAuthStore } from "../stores/authStore";
import { getClient } from "./graphqlClient";
import { useRouter } from "next/router";
import { useMemo } from "react";
import { memo } from "react";
import { useSnackbar } from "notistack";
import useTranslation from "../hooks/useTranslation";
import { useCallback } from "react";

/**
 * A React component that provides an instance of ApolloClient to its children.
 * 
 * @param {ReactNode} children - The children components to be wrapped by the ApolloProvider.
 */
const GraphqlProvider = ({ children }) => {
    /**
     * The authentication token from the authStore.
     */
    const token = useAuthStore((state) => state.token);

    /**
     * The next js router.
     */
    const router = useRouter();

    /**
     * The function to notify.
     */
    const { enqueueSnackbar } = useSnackbar();

    /**
     * The translate function.
     */
    const { t } = useTranslation()

    /**
     * Function to handle what happen when session expired.
     */
    const handleSignOut = useCallback(() => {
        enqueueSnackbar(t("Your session has expired. Please log in again to continue. Don't worry, your cart is saved."), { variant: 'warning' })
        router.replace(`/customer/login.html?redirect=${router.asPath}`)
    }, [])

    /**
     * The locale from the router.
     */
    const { locale } = router

    /**
     * The ApolloClient instance created by the getClient function.
     */
    const client = useMemo(() => (getClient(locale, token, false, handleSignOut)), [locale, token, handleSignOut]);
    console.log(locale);
    return (
        /**
         * The ApolloProvider component that provides the ApolloClient instance to its children.
         */
        <ApolloProvider client={client} >
            {children}
        </ApolloProvider>
    );
}

/**
 * Exports the GraphqlProvider component as a memoized component.
 */
export default memo(GraphqlProvider);